<template>
  <div>
    <div>
      <img src="../img/reg-bg.png" alt="" id="bg" />
    </div>
    <div id="mask_body" style="top: 44px">
      <img
        src="../img/logo-i.png"
        alt=""
        class="logo58 customization_elements"
      />
      <span class="logo">聚信人和</span>
    </div>
    <div class="password_login">
      <div class="mask_body_item change_login">
        <span class="account_item active">账号密码登录</span>
      </div>
      <div class="account_login">
        <div class="mask_body_item" id="mask_body_item_user_name">
          <input
            type="text"
            class="pop_input"
            id="mask_body_item_username"
            placeholder="手机号"
            v-model="phone"
          />
        </div>
        <div class="mask_body_item">
          <input
            type="password"
            placeholder="密码"
            class="pop_input1"
            id="mask_body_item_newpassword"
            maxlength="16"
            v-model="password"
            @keyup.enter="getdenglu"
          />
        </div>
        <div class="mask_body_item login_item baseColorBg loginBtn">
          <button
            id="mask_body_item_login"
            class="btn2"
            style="opacity: 0.8"
            @click.enter="getdenglu"
          >
            登录
          </button>
          <router-link to="/zhuce" class="lianjie">注册账号</router-link>
          <router-link to="/" class="lianjie1">返回首页</router-link>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div>
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号-2</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >津文执网文【2019】</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >河北坤洲网络科技有限公司</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          >联系我们</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Cookies from "js-cookie";
export default {
  name: "Denglu",
  data() {
    return {
      phone: "",
      password: "",
      //获取后台数据
      iphone: {},
    };
  },
  methods: {
    getdenglu() {
      axios.all([

      ])
      axios
        .post(
          // "http://192.168.28.37:15001/account/login",
          "http://123.56.43.64:15001/account/login",
          {
            phone: this.phone,
            password: this.password,
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.msg == "登录失败,用户不存在") {
            alert(res.data.msg);
          } else {
            // console.log("砂浆款搭嘎岁", res.data.data.user[0].phone);
            // console.log("在进行此远古时代", res.data.data.token[0]);
            console.log("现在才不会压缩孤独死",res.data.data.user[0].id);
            Cookies.set("phone", res.data.data.user[0].phone);
            Cookies.set("token", res.data.data.token[0]);
            Cookies.set("platnum", res.data.data.user[0].platnum);
            this.$router.push("./gerenzhongxin");
          }
        });
    },
  },
};
</script>
<style scoped>
#bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
#mask_body,
#new_mask_body {
  position: absolute;
  top: 100px;
  background-color: #fff;
  margin-left: 50%;
  left: -238px;
  width: 476px;
  height: 400px;
  padding-top: 62px;
  box-shadow: 0 2px 6px 2px rgb(0 0 0 / 24%);
  border-radius: 2px;
}
.logo58 {
  height: 36px;
  display: block;
  margin: 0 auto 0 180px;
  margin-bottom: 26px;
  vertical-align: bottom;
}
.logo {
  display: block;
  margin-left: 220px;
  margin-top: -58px;
  color: rgba(177, 171, 171, 0.966);
  font-size: 20px;
  font-weight: 800;
}
.change_login {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 10px;
  padding: 0;
  height: 50px;
  margin-top: 10px;
}
.active {
  color: #ff552e;
  border-bottom: 1px solid #ff552e;
}
.account_item {
  margin-left: 85px;
}
.change_login span {
  width: 50%;
  text-align: center;
  float: left;
  cursor: pointer;
  font-size: 16px;
  line-height: 55px;
}
.mask_body_item {
  width: 350px;
  font-size: 14px;
  margin: 0 auto;
  position: relative;
  padding: 13px 0;
  clear: both;
  margin-top: 150px;
}
#mask_body_item_user_name {
  position: relative;
}
.mask_body_item input {
  width: 331px;
  padding-left: 15px;
  border: 2px solid #ebebeb;
  height: 36px;
  line-height: unset;
  line-height: 36px\9;
  color: #000;
  outline: none;
  font-size: 14px;
}
.pop_input {
  display: block;
  margin-top: -150px;
}
.pop_input1 {
  display: block;
  margin-top: -260px;
}
#mask_body_item_login {
  display: block;
  width: 100px;
  height: 40px;
  border: none;
  color: #fff;
  font-size: 14px;
  line-height: 42px;
  outline: none;
  cursor: pointer;
}
.btn2 {
  background: #ff552e;
  display: block;
  margin-top: -350px;
  margin-left: 50px;
  color: #fff;
}
a {
  text-decoration: none;
}
.btn a {
  color: aliceblue;
}
.lianjie {
  display: block;
  width: 60px;
  margin-left: 180px;
  margin-top: -20px;
  color: rgb(88, 150, 250);
}
.lianjie1 {
  display: block;
  width: 60px;
  margin-left: 270px;
  margin-top: -20px;
  color: rgb(88, 150, 250);
}
.bottom {
  overflow: hidden;
  width: 100%;
  height: 150px;
  /* background-color: red; */
  /* border: 1px solid black; */
  margin: 0 auto;
  line-height: 16px;
  text-align: center;
  color: #999;
}
.bottom div {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 90px;
  border-top: 1px solid #999;
}
.bottom div span {
  margin: 0 10px;
  color: #eee;
}

.bottom div a {
  color: #999;
  font-size: 13px;
  /* margin-left: 20px; */
}
</style>
